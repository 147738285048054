import AppContext from "context/Context";
import { getColor, getItemFromStore } from "helpers/utils";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { settings } from "./config";
import useToggleStyle from "./hooks/useToggleStyle";
import { configReducer } from "./reducers/configReducer";

import { LoginAPI } from "api/LoginAPI";
import { Chart as ChartJS, registerables } from "chart.js";
import { toast } from "react-toastify";
import { useFetchMenus } from "hooks/useFetchMenu";
import { useQuery } from "@tanstack/react-query";
import { MenusAPI } from "api";

ChartJS.register(...registerables);

const initialState = {
  isLoading: false,
  user: null,
  tokenExpiry: null,
  token: localStorage.getItem("token"),
};

// console.log(initialState.user);

const Main = (props) => {
  const [state, setState] = useState(initialState);

  const login = async (loginId, password) => {
    const data = { loginId, password };

    const response = await LoginAPI.post(data);
    const menus = await MenusAPI.get();

    setState((state) => ({
      ...state,
      token: response?.data.token,
      tokenExpiry: response.data.expiry,
      user: response.data.userInfo,
      isLoading: false,
    }));

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("tokenExpiry", response.data.expiry);
    localStorage.setItem("user", JSON.stringify(response.data.userInfo));
    if (menus) {
      localStorage.setItem("menus", JSON.stringify(menus?.data?.data));
    }
  };

  const logout = async () => {
    localStorage.clear();
    setState((state) => ({
      ...state,
      user: null,
      token: null,
      tokenExpiry: null,
      menus: null,
    }));
  };

  useEffect(() => {
    const fetchMenus = async () => {
      const token = localStorage.getItem("token");
      const user = localStorage.getItem("user");
      const menus = localStorage.getItem("menus");

      if (token && user && (menus === null || menus === undefined)) {
        try {
          const menusFetch = await MenusAPI.get();
          localStorage.setItem("menus", JSON.stringify(menusFetch?.data?.data));
        } catch (error) {
          console.error("Error fetching menus:", error);
        }
      }
    };

    fetchMenus();
  }, []);

  const configState = {
    isFluid: getItemFromStore("isFluid", settings.isFluid),
    isRTL: getItemFromStore("isRTL", settings.isRTL),
    isDark: getItemFromStore("isDark", settings.isDark),
    theme: getItemFromStore("theme", settings.theme),
    navbarPosition: getItemFromStore("navbarPosition", settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      "isNavbarVerticalCollapsed",
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore("navbarStyle", settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    openAuthModal: false,
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const setConfig = (key, value) => {
    configDispatch({
      type: "SET_CONFIG",
      payload: {
        key,
        value,
        setInStore: [
          "isFluid",
          "isRTL",
          "isDark",
          "theme",
          "navbarPosition",
          "isNavbarVerticalCollapsed",
          "navbarStyle",
        ].includes(key),
      },
    });
  };
  const { isLoaded } = useToggleStyle(config.isRTL, config.isDark);

  useEffect(() => {
    const isDark =
      config.theme === "auto"
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
        : config.theme === "dark";

    setConfig("isDark", isDark);
  }, [config.theme]);

  const changeTheme = (theme) => {
    const isDark =
      theme === "auto"
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
        : theme === "dark";

    document.documentElement.setAttribute(
      "data-bs-theme",
      isDark ? "dark" : "light"
    );

    setConfig("theme", theme);
    setConfig("isDark", isDark);
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor("dark") : getColor("light"),
        }}
      />
    );
  }

  // const values = useMemo(
  //   () => ({
  //     ...state,
  //     login,
  //     config,
  //     setConfig,
  //     configDispatch,
  //     changeTheme,
  //   }),
  //   [state]
  // );

  return (
    <AppContext.Provider
      value={{
        config,
        setConfig,
        configDispatch,
        changeTheme,
        login,
        state,
        logout,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export const useAppContext = () => useContext(AppContext);
export default Main;
