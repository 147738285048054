import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import AddSpaceTable from "./AddSpaceTable";
import ActionButton from "components/common/ActionButton";
import IconButton from "components/common/IconButton";
import { useFetchAuthorities } from "hooks/useFetchAuthorities";
import Select from "react-select";
import { useFetchCategories } from "hooks/useFetchCategories";

const options = [];

const SpaceTypeBasicInformation = () => {
  const {
    register,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext();

  const {
    fields: details,
    append: appendDetails,
    remove: removeDetails,
  } = useFieldArray({
    control,
    name: "details",
  });

  const { state } = useLocation();
  const navigate = useNavigate();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);

  const handleCancel = () => {
    navigate(-1);
  };

  const { data: authoritiesData, isLoading: isAuthoritiesLoading } =
    useFetchAuthorities();

  const { data: categoriesData, isLoading: categoriesLoading } =
    useFetchCategories();

  let rawData = categoriesData?.data?.data;

  console.log(rawData);

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  useEffect(() => {
    register("parentId1", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("parentId1", state.parentId1);
    }
  }, [state, setValue]);

  const handleSelectChange = (selectedOption) => {
    setValue("parentId1", selectedOption ? selectedOption.value : "");
    if (selectedOption) {
      clearErrors("parentId1");
    }
  };

  const handleInputFocus = () => {
    clearErrors("parentId1");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.parentId1 ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.parentId1 ? "red" : provided["&:hover"].borderColor,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Set the text color of the selected value to black
      padding: "7px",
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: "7px",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "7px",
      paddingRight: "7px",
      // padding: "12px", // Set the input text color to black
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  if (isAuthoritiesLoading) {
    return <Spinner variant="primary" animation="border" />;
  }

  const flattenTree = (tree, parentId = null, level = 0) => {
    return tree.reduce((acc, node) => {
      const { children, ...rest } = node;
      acc.push({ ...rest, parentId, level });
      if (children && children.length) {
        acc.push(...flattenTree(children, node.id, level + 1));
      }
      return acc;
    }, []);
  };

  const transformToSelectOptions = (data) => {
    return data.map((item) => ({
      value: item.id,
      label: `${"\u00A0".repeat(item.level * 4)}${item.name}`, // Use non-breaking spaces for indentation
    }));
  };

  const buildTree = (data) => {
    const tree = [];
    const lookup = {};

    data.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    data.forEach((item) => {
      if (item.parentId1 !== 0) {
        lookup[item.parentId1].children.push(lookup[item.id]);
      } else {
        tree.push(lookup[item.id]);
      }
    });

    return tree;
  };

  if (categoriesLoading) {
    return <Spinner variant="primary" animation="border" />;
  }

  const treeData = buildTree(rawData);
  const flattenedData = flattenTree(treeData);
  const selectOptions = transformToSelectOptions(flattenedData);

  let initialSelectedOption = selectOptions?.find(
    (option) => option.value == state?.parentId1
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption = state
      ? { label: state?.parentId1, value: state?.parentId1 }
      : undefined;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <Select
                options={selectOptions}
                isClearable
                placeholder={
                  <h6 className="mb-2 mb-md-0">
                    Space Category{" "}
                    {/* <span style={{ color: "red", fontSize: "12px" }}>*</span> */}
                  </h6>
                }
                className={`${errors.parentId1 ? "is-invalid" : ""}`}
                styles={customStyles}
                autoFocus
                onChange={handleSelectChange}
                onFocus={handleInputFocus}
                onInputChange={handleInputFocus}
                defaultValue={initialSelectedOption}
              />
              <Form.Control.Feedback type="invalid">
                {errors.parentId1?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Space Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Space Description"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.strength}
                  {...register("strength")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.strength?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Authority Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.authorityName}
                  {...register("authorityName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.authorityName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Advertisement Category "
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.advertisementCategory}
                  {...register("advertisementCategory")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.advertisementCategory?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Rate
                    <sup>sq.</sup> <sup>ft.</sup>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.rate}
                  {...register("rate")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rate?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Duration">
                <Form.Select
                  aria-label="duration"
                  isInvalid={!!errors.duration}
                  {...register("duration")}
                >
                  <option value="">Select</option>

                  <option value="Per Day">Per Day</option>
                  <option value="Per Week">Per Week</option>
                  <option value="Per Month">Per Month</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.duration?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Type"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoFocus
                  isInvalid={!!errors.type}
                  {...register("type")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Role">
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Card className="shadow-none border my-4">
          <Row className="gx-2 gy-3 my-1">
            {/* <Col className="d-flex justify-content-end">
            
              <IconButton
                onClick={() => appendCategoriesData({})}
                variant="primary"
                className="me-2 "
                icon="plus"
                transform="shrink-3"
              >
                Add
              </IconButton>
            </Col> */}
            <Table responsive>
              <thead className="bg-200">
                <tr>
                  <th scope="col" className="fs-10">
                    Authority Name
                  </th>
                  <th scope="col" className="fs-10">
                    Advertisement Category
                  </th>
                  <th scope="col" className="fs-10">
                    Rate <sup>sq.</sup>
                    <sup>ft.</sup>
                  </th>
                  <th scope="col" className="fs-10">
                    Duration
                  </th>
                  <th className="text-end" scope="col">
                    {/* Actions */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {details.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      {/* <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Control
                          type="text"
                          placeholder="Authority Name"
                          isInvalid={
                            !!errors.categoriesData?.[index]?.authorityName
                          }
                          {...register(
                            `categoriesData[${index}].authorityName`
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {
                            errors.categoriesData?.[index]?.authorityName
                              ?.message
                          }
                        </Form.Control.Feedback>
                      </Form.Group> */}
                      <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Select
                          aria-label="authorityName"
                          isInvalid={!!errors.details?.[index]?.field1}
                          {...register(`details[${index}].field1`)}
                        >
                          <option value="">Select</option>
                          {authoritiesData?.data?.data?.map((d) => (
                            <option value={d.id}>{d.name}</option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.details?.[index]?.field1?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Control
                          type="text"
                          placeholder="Advertisement Category"
                          isInvalid={!!errors.details?.[index]?.field2}
                          {...register(`details[${index}].field2`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.details?.[index]?.field2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Control
                          type="number"
                          placeholder="Rate"
                          isInvalid={!!errors.details?.[index]?.field3}
                          {...register(`details[${index}].field3`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.details?.[index]?.field3?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Select
                          aria-label="duration"
                          isInvalid={!!errors.details?.[index]?.field4}
                          {...register(`details[${index}].field4`)}
                        >
                          <option value="">Select</option>

                          <option value="Per Day">Per Day</option>
                          <option value="Per Week">Per Week</option>
                          <option value="Per Month">Per Month</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.details?.[index]?.field4?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      {/* {index > 0 && (
                        <ActionButton
                          icon="trash-alt"
                          title="Delete"
                          variant="action"
                          className="p-0"
                          onClick={() => removeCategoriesData(index)}
                        />
                      )} */}

                      {index !== details.length - 1 && (
                        <ActionButton
                          icon="trash-alt"
                          title="Delete"
                          variant="danger"
                          className="px-1 py-0"
                          onClick={() => removeDetails(index)}
                        />
                      )}

                      {index === details.length - 1 && (
                        // <IconButton
                        //   onClick={() => appendCategoriesData({})}
                        //   // variant="primary"
                        //   className="me-2"
                        //   icon="plus"
                        //   transform="shrink-3"
                        // ></IconButton>
                        <ActionButton
                          icon="plus"
                          variant="success"
                          title="Add"
                          className="px-1 py-0"
                          onClick={() => appendDetails({})}
                        />
                      )}
                    </td>

                    {/* <td className="text-end">
                    {index > 0 && (
                      <ActionButton
                        icon="trash-alt"
                        title="Delete"
                        variant="action"
                        className="p-0"
                        onClick={() => removeCategoriesData(index)}
                      />
                    )}
                    <ActionButton
                      icon="edit"
                      title="Edit"
                      variant="action"
                      className="p-0 me-2"
                    />
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* <div>
            {categoriesData.map((item, index) => (
              <div key={item.id}>
                <label>authorityName:</label>
                <input
                  {...register(`categoriesData[${index}].authorityName`)}
                />
                <span>
                  {errors.categoriesData?.[index]?.authorityName?.message}
                </span>

                <label>advertisementCategory:</label>
                <input
                  {...register(
                    `categoriesData[${index}].advertisementCategory`
                  )}
                />
                <span>
                  {
                    errors.categoriesData?.[index]?.advertisementCategory
                      ?.message
                  }
                </span>

                <label>rate:</label>
                <input {...register(`categoriesData[${index}].rate`)} />
                <span>{errors.categoriesData?.[index]?.rate?.message}</span>

                <label>Duration:</label>
                <Form.Select
                  aria-label="duration"
                  // isInvalid={!!errors.duration}
                  {...register(`categoriesData[${index}].duration`)}
                >
                  <option value="">Select</option>

                  <option value="Per Day">Per Day</option>
                  <option value="Per Week">Per Week</option>
                  <option value="Per Month">Per Month</option>
                </Form.Select>

                <input {...register(`categoriesData[${index}].duration`)} />
                <span>{errors.categoriesData?.[index]?.duration?.message}</span>

                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeCategoriesData(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={() => appendCategoriesData({})}>
              Add
            </button>
          </div> */}
          </Row>
        </Card>
        <Col md="12">
          <Form.Check
            type="switch"
            id="defaultSwitch"
            label="Active"
            defaultChecked={!state}
            isInvalid={!!errors.active}
            {...register("active")}
          />
        </Col>
        {/* <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {update ? "Ok" : "OK"}
            </Button>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default SpaceTypeBasicInformation;
